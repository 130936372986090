
.navbar .nav-link {
  color: hsla(0, 0%, 100%, .5);
}

.navbar .nav-link:hover {
  color: white;
}

.navbar .nav-link.active {
  color: white;
  text-decoration: underline;
  text-decoration-color: #28a745;
  text-underline-offset: 5px;
}
