body {
  /* background-color:  #282c34; */
  background-color: #ddd;
  margin-top: 50px;
}

.wrapper {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 93.4vh;
}

@media (min-width: 1600px) {
  .container {
    max-width: 1500px;
  }
}

.container {
  max-width: 1500px;
}

.panel {
  border: 1px solid #CCC;
  border-radius: 5px;
  padding: 20px;
  background-color: #fff;
}

.help {
  color: grey;
}

.error {
  color: red;
}

.smallfont {
  font-size: 0.8em;
}