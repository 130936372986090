.legend-circle {
    width: 20px;
    height: 20px;
    border: 1px solid black;
    border-radius: 50%;
    opacity: 1;
    display: inline-block;
}

.legend-square {
    background: white;
    width: 20px;
    height: 20px;
    border: 1px solid black;
    display: inline-block;
    opacity: 1;
}

.legend-text {
    display: inline-block;
    height: 20px;
    margin-top: 4px;
    line-height: 20px;
}

.legend {
    line-height: 18px;
    color: #555;
}
