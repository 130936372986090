
.rt-expander-button {
  margin: 0 2px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

.rt-expander {
  display: inline-block;
  position: relative;
  padding: 0 8px;
  color: transparent;
  outline-width: 0;
  outline-style: solid;
}

.rt-expander.rt-expander-open::after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);

}

.rt-expander::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, .8);
  -webkit-transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
  cursor: pointer;
}

.rt-expander::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, .8);
  -webkit-transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
  cursor: pointer;
}


.rt-page-jump,
.rt-page-size-select {
  background-color: #fff;
  padding: 3px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, .05);
}

.rt-page-button,
.rt-page-jump,
.rt-page-size-select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  line-height: inherit;
}

.rt-page-size-select {
  margin: 0 2px;
}

.rt-page-button {
  padding: 6px 12px;
  background-color: transparent;
  border: none;
  border-radius: 3px;
  outline-width: 0;
  outline-style: solid;
  cursor: pointer;
}

.rt-page-ellipsis {
  margin: 0 4px;
  pointer-events: none;
}

.rt-page-button-current {
  font-weight: 700;
}

.rt-pagination-nav {
  float: right;
}

.rt-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 6px 4px;
  border-top: 1px solid #f2f2f2;
}
