.auth-form {
  max-width: 300px;
  margin: 0 auto;
  margin-top: 100px;
  background-color: #fff;
  border: 1px solid #d8dee2;
  border-top: 1px solid #d8dee2;
  border-radius: 5px;
  /* width: "100%";
  height: "100vh";
  display: "flex";
  justify-content: "center";
  align-items: "center"; */
}

.auth-form-body {
  max-width: 280px;
  margin: 0 auto;
  padding: 20px;
  font-size: 14px;
}

.auth-form-wide {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 70px;
  background-color: #fff;
  border: 1px solid #d8dee2;
  border-top: 1px solid #d8dee2;
  border-radius: 5px;
  /* width: "100%";
  height: "100vh";
  display: "flex";
  justify-content: "center";
  align-items: "center"; */
}

.auth-form-body-wide {
  max-width: 780px;
  margin: 0 auto;
  padding: 20px;
  font-size: 14px;
}
